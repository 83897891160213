body {
  margin: 0;
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
