.non-profit-signup.third-step {
  .nonprofit-content {
    p:not(.card-error) {
      margin: 30px;
    }

    .component-input {
      text-align: center;
      padding-left: 0;
    }

    .resend {
      font-family: Josefin Sans, Open Sans, sans-serif;
      color: #d98c68;
      font-size: 25px;
      background: transparent;
      text-decoration: underline;
      border: 0;
      cursor: pointer;
      margin-top: 60px;
    }
    @media only screen and (max-width: 600px) {
      p:not(.card-error) {
        margin: 20px 0 0;
        font-size: 18px;
      }
      .resend {
        font-size: 20px;
      }
    }
  }
}
